import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useAuth } from "@clerk/nextjs";

const FooterColumns = dynamic(() => import("./FooterColumns"));
const SocialLinks = dynamic(
  () => import("components/links/SocialLinks/SocialLinks")
);
const JoinUs = dynamic(() => import("components/JoinUs"));

import styles from "./Footer.module.scss";
import useWindowSize from "lib/hooks/useWindowSize";

const COLUMNS_DATA = {
  forCopyRight:
    "© Copyright 2024 WhereNext Experiences Pty. Ltd.  All rights reserved.",
  forTandC: "Terms | Privacy\n\n",
  logoDescription:
    "Travel experiences with a focus on volunteering and animal conservation",
  column1: {
    text: "",
    title: "For business",
    links: [
      {
        link: "/about/partner-with-us",
        linkName: "Partner with Us",
      },
      {
        link: "/about/corporate-social-responsibility",
        linkName: "Corporate Social Responsibility",
      },
      {
        link: "/contact",
        linkName: "Contact Us",
      },
    ],
  },
  column2: {
    text: "",
    title: "Our charities",
    links: [
      {
        link: "/about/one-percent-for-the-planet",
        linkName: "1% for the Planet",
      },
      {
        link: "https://pledge1percent.org/",
        linkName: "Pledge 1%",
        target: "_blank",
      },
      {
        link: "https://www.kiva.org/",
        linkName: "Kiva",
        target: "_blank",
      },
      {
        link: "/about/one-tree-planted",
        linkName: "One Tree Planted",
      },
    ],
  },
  column3: {
    text: "We partner with travel providers and charities that give back to local communities.",
    title: "Our partners",
  },
  column4: {
    text: "",
    title: "Categories",
    links: [
      {
        link: "/experiences/adventure",
        linkName: "Adventure",
      },
      {
        link: "/experiences/animals",
        linkName: "Animals",
      },
      {
        link: "/experiences/internships",
        linkName: "Internships",
      },
      {
        link: "/experiences/learning",
        linkName: "Learning",
      },
      {
        link: "/experiences/teaching",
        linkName: "Teaching",
      },
      {
        link: "/experiences/volunteering",
        linkName: "Volunteering",
      },
      {
        link: "/experiences/yoga",
        linkName: "Yoga",
      },
    ],
  },
};

const Footer = ({ showEmailSignup = true, footerData, showFooter }) => {
  const { isSignedIn } = useAuth();
  const windowSize = useWindowSize();

  if (!showFooter?.mobile && windowSize?.width < 768) {
    return null;
  }

  return (
    <>
      {showEmailSignup && !isSignedIn && <JoinUs />}
      <div className={`${styles.footerArea}`}>
        <div className="variable-container">
          <div className={`${styles.footerRow}`}>
            <div className={`${styles.column} ${styles.logoColumn}`}>
              <Link href="/" aria-label="home">
                <Image
                  width={123}
                  height={24}
                  src={"/assets/img/logo_full.svg"}
                  alt="WhereNext Logo"
                />
              </Link>
              <div className={`${styles.logoDescription}`}>
                <p>{COLUMNS_DATA?.logoDescription}</p>
              </div>
              <div className={`${styles.socialContacts}`}>
                <span>Let’s chat:</span>
                <SocialLinks
                  include={["messenger", "whatsapp"]}
                  iconColor="var(--light-text"
                  links={footerData?.socialLinks}
                />
              </div>
            </div>
            <FooterColumns footerData={COLUMNS_DATA} />
          </div>

          <div className={`${styles.bottomBar}`}>
            <div className={`${styles.tcLinks}`}>
              <Link href="/terms" aria-label="Terms">
                Terms
              </Link>
              &nbsp; | &nbsp;
              <Link href="/privacy" aria-label="Privacy">
                Privacy
              </Link>
            </div>
            <p className={`${styles.copyright}`}>
              {COLUMNS_DATA?.forCopyRight}
            </p>
            <div className={`${styles.socialLinks}`}>
              <SocialLinks
                include={["facebook", "instagram", "twitter", "linkedin"]}
                iconColor="var(--light-text"
                links={footerData?.socialLinks}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
